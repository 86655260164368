import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// -----------------------------------------------------------

Logo.propTypes = {
	disabledLink: PropTypes.bool,
	sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
	const theme = useTheme();
	const PRIMARY_LIGHT = theme.palette.primary.light;
	const PRIMARY_MAIN = theme.palette.primary.main;
	const PRIMARY_DARK = theme.palette.primary.dark;
	const logo = (
		<Box sx={{ width: 40, height: 40, ...sx }}>
			<svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 1080.000000 1052.000000" >
				<defs>
					<linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
						<stop offset="0%" stopColor={PRIMARY_DARK} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
					<linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
						<stop offset="0%" stopColor={PRIMARY_LIGHT} />
						<stop offset="100%" stopColor={PRIMARY_MAIN} />
					</linearGradient>
				</defs>
				<g
					fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,1052.000000) scale(0.100000,-0.100000)" >

<path d="M0 5260 l0 -5260 5400 0 5400 0 0 5260 0 5260 -5400 0 -5400 0 0
-5260z m5810 4924 c286 -30 329 -35 445 -55 713 -119 1390 -387 2000 -791 496
-329 934 -747 1281 -1223 440 -602 742 -1318 863 -2043 55 -333 64 -453 58
-865 -5 -388 -15 -502 -67 -793 -50 -279 -172 -695 -285 -969 -105 -257 -290
-603 -453 -848 -632 -948 -1594 -1666 -2697 -2013 -566 -178 -1084 -252 -1686
-241 -547 10 -948 74 -1469 234 -462 141 -975 387 -1359 651 -748 514 -1311
1161 -1690 1942 -107 221 -175 387 -248 610 -302 916 -318 1903 -47 2837 129
444 330 880 598 1293 45 69 86 132 93 140 6 8 46 62 89 120 600 816 1528 1472
2529 1788 343 108 628 167 1058 218 297 35 700 38 987 8z"/>
<path d="M2944 9462 c-143 -123 -300 -329 -385 -508 -128 -269 -190 -563 -191
-919 -4 -821 359 -1786 1011 -2685 42 -58 81 -112 88 -120 167 -214 271 -339
403 -485 64 -72 380 -387 481 -480 123 -115 351 -303 523 -434 671 -508 1426
-870 2111 -1011 207 -42 333 -59 537 -71 534 -31 1016 105 1367 385 79 64 241
232 241 251 0 8 -35 -8 -92 -43 -190 -114 -396 -184 -678 -229 -159 -25 -582
-25 -750 0 -304 46 -606 124 -880 227 -250 94 -297 114 -542 237 -430 217
-839 485 -1208 792 -319 265 -699 635 -900 876 -68 83 -144 174 -168 203 -70
83 -245 324 -337 462 -313 468 -575 1014 -714 1485 -182 620 -199 1224 -46
1692 38 115 129 311 177 381 17 24 28 46 25 48 -3 3 -36 -21 -73 -54z"/>
<path d="M4753 9093 c-62 -74 -84 -107 -127 -194 -60 -122 -80 -213 -79 -364
0 -71 5 -150 11 -175 6 -25 16 -65 22 -90 32 -131 184 -361 243 -368 52 -6 48
35 -10 105 -82 97 -103 130 -151 243 -31 71 -40 108 -53 212 -13 105 -2 217
27 268 8 14 32 61 55 105 22 45 69 115 104 158 63 75 75 99 55 112 -5 3 -7 12
-4 19 3 9 -4 15 -20 18 -22 4 -34 -4 -73 -49z"/>
<path d="M5923 9065 c-3 -10 10 -34 34 -63 87 -105 150 -230 177 -352 19 -81
22 -249 7 -312 -5 -21 -6 -36 -2 -33 19 11 34 148 28 252 -9 179 -63 324 -169
456 -56 70 -65 76 -75 52z"/>
<path d="M5235 8980 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M4974 8928 c-12 -13 -29 -36 -39 -53 -16 -27 -14 -25 25 11 23 23 41
47 39 53 -3 8 -12 3 -25 -11z"/>
<path d="M5790 8922 c0 -9 18 -40 41 -67 43 -52 101 -158 114 -210 4 -16 10
-24 12 -17 7 17 -32 131 -60 180 -30 51 -90 131 -99 132 -5 0 -8 -8 -8 -18z"/>
<path d="M5660 8779 c0 -8 13 -30 28 -49 16 -19 34 -46 41 -58 7 -13 15 -22
17 -19 8 8 -42 100 -65 121 -16 15 -21 16 -21 5z"/>
<path d="M5962 8580 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5640 8520 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5290 8395 c0 -18 1 -18 36 0 l29 15 -32 0 c-23 0 -33 -5 -33 -15z"/>
<path d="M5140 8376 c0 -8 4 -17 9 -20 11 -7 33 18 24 27 -12 12 -33 7 -33 -7z"/>
<path d="M5284 8351 c-2 -2 -4 -21 -4 -42 0 -35 3 -39 33 -47 l32 -8 -32 -12
c-56 -19 -35 -51 35 -52 12 0 22 -4 22 -10 0 -5 -12 -10 -27 -10 -23 0 -31 -7
-45 -43 -23 -55 -26 -176 -4 -158 10 8 30 10 60 6 38 -6 46 -11 46 -29 0 -11
4 -27 9 -35 7 -11 11 -10 20 7 6 11 11 27 11 35 0 21 41 29 60 12 25 -22 30
-9 30 76 0 63 -3 77 -14 73 -7 -3 -23 7 -35 23 -21 26 -21 28 -5 40 10 7 26
11 36 7 16 -5 18 2 18 76 0 56 -4 82 -12 85 -19 6 -228 11 -234 6z m223 -107
c-3 -4 -19 -3 -34 1 l-28 8 25 7 c25 7 50 -4 37 -16z m-36 -156 c42 -50 43
-58 4 -58 -34 0 -35 1 -35 40 0 46 4 49 31 18z m-106 -17 c7 -12 -22 -41 -41
-41 -18 0 -18 9 3 32 18 20 29 23 38 9z"/>
<path d="M5200 8334 c-16 -19 -3 -47 24 -52 14 -3 17 3 14 29 -3 37 -19 47
-38 23z"/>
<path d="M5615 8330 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5043 8263 c24 -46 64 -75 88 -62 26 14 24 27 -4 22 -17 -3 -34 6
-62 34 l-38 38 16 -32z"/>
<path d="M4820 8240 c0 -12 54 -104 87 -147 31 -40 56 -55 77 -47 24 9 19 34
-6 34 -19 0 -20 2 -8 10 12 8 10 10 -10 10 -27 1 -89 65 -117 120 -15 31 -23
38 -23 20z"/>
<path d="M5672 8199 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9 0
-15 -5 -13 -11z"/>
<path d="M5790 8066 c0 -25 30 -43 50 -31 15 9 12 25 -5 25 -9 0 -18 7 -21 15
-9 23 -24 18 -24 -9z"/>
<path d="M5223 8045 c-19 -21 -17 -105 2 -105 12 0 15 13 15 60 0 33 -1 60 -2
60 -2 -1 -9 -7 -15 -15z"/>
<path d="M5931 7951 c-20 -13 -6 -57 21 -64 12 -3 25 -4 30 -1 13 8 9 24 -6
24 -8 0 -18 11 -23 25 -6 17 -12 22 -22 16z"/>
<path d="M5504 7932 c-19 -13 -67 -83 -60 -89 3 -3 18 -1 32 6 14 6 29 9 34 6
11 -7 24 65 13 76 -4 4 -13 5 -19 1z"/>
<path d="M5215 7909 c-4 -6 -3 -15 3 -21 6 -6 11 -4 15 6 7 18 -9 31 -18 15z"/>
<path d="M5280 7790 c0 -17 4 -19 25 -14 30 8 31 1 4 -43 -12 -19 -25 -50 -29
-69 -7 -31 -6 -34 8 -22 55 41 70 64 73 113 l3 50 -42 3 c-37 3 -42 1 -42 -18z"/>
<path d="M5436 7796 c4 -10 19 -16 40 -16 26 0 37 -5 44 -22 8 -20 9 -18 7 12
-2 34 -3 35 -49 38 -40 3 -47 0 -42 -12z"/>
<path d="M5401 7733 c1 -21 5 -51 9 -68 l7 -30 8 33 c10 41 28 41 70 -1 l33
-33 -3 40 c-3 37 -6 41 -30 41 -18 1 -34 10 -47 28 -29 40 -48 35 -47 -10z"/>
<path d="M5275 7603 c-55 -5 -65 -13 -65 -60 0 -17 -4 -34 -9 -37 -5 -3 -9
-83 -9 -177 l-1 -171 72 7 c42 4 92 2 121 -5 36 -8 52 -8 62 1 10 8 38 10 88
5 62 -6 75 -4 80 9 3 9 2 67 -4 128 -5 62 -10 147 -10 189 l0 77 -34 16 c-25
12 -57 16 -113 14 -43 -1 -89 0 -103 3 -14 2 -47 3 -75 1z m83 -57 c6 -48 -16
-56 -48 -17 -34 40 -30 53 13 49 27 -3 33 -8 35 -32z m152 24 c0 -5 -4 -10
-10 -10 -5 0 -10 -5 -10 -12 0 -14 -30 -48 -42 -48 -18 0 -7 48 15 63 25 18
47 21 47 7z m50 -108 c0 -27 -2 -51 -5 -53 -2 -3 -20 -3 -40 -1 -39 5 -46 23
-17 44 11 7 24 23 31 36 19 36 31 26 31 -26z m-248 -9 c26 -23 23 -43 -7 -43
-21 0 -25 5 -25 30 0 34 6 37 32 13z m-6 -83 c22 0 17 -13 -22 -64 -42 -56
-59 -52 -49 13 7 47 20 64 43 56 8 -3 20 -5 28 -5z m254 -9 c12 -24 13 -101 1
-101 -11 0 -91 87 -91 99 0 25 77 26 90 2z m-43 -129 c12 -13 23 -25 23 -28 0
-3 -24 -4 -52 -2 l-53 3 3 35 c6 60 10 59 79 -8z m-164 -11 c-4 -17 -13 -21
-44 -21 -22 0 -39 4 -39 9 0 5 17 23 37 40 35 29 38 30 45 12 3 -10 4 -28 1
-40z"/>
<path d="M2941 7257 c-5 -5 9 -54 29 -110 l38 -102 603 -3 c332 -1 610 0 616
3 9 3 10 11 3 30 -5 14 -13 61 -16 105 l-7 80 -629 2 c-346 2 -633 -1 -637 -5z"/>
<path d="M6496 7248 c-3 -7 -7 -40 -10 -73 -3 -33 -9 -77 -12 -97 l-7 -38 636
-2 c625 -3 636 -3 664 -24 15 -11 36 -36 45 -55 17 -32 18 -114 18 -1283 0
-1238 0 -1250 -20 -1292 -39 -80 70 -74 -1378 -74 -710 0 -1293 -4 -1296 -8
-6 -10 95 -87 269 -204 l129 -88 1246 0 c685 0 1250 4 1257 8 6 4 17 20 22 35
8 20 11 492 11 1525 -1 1224 -3 1504 -14 1535 -20 54 -61 104 -106 127 -38 19
-60 20 -745 20 -556 0 -706 -3 -709 -12z"/>
<path d="M5265 6855 c-39 -36 -81 -65 -95 -67 -24 -3 -25 -8 -57 -198 -18
-107 -32 -196 -33 -198 0 -2 16 -10 35 -18 37 -15 80 -10 93 10 10 17 98 76
112 76 8 0 11 -14 8 -42 -3 -24 -1 -54 4 -67 7 -20 13 -23 44 -18 66 11 113
57 58 57 -14 0 -23 4 -19 10 3 6 13 10 22 10 9 0 21 7 28 16 10 15 14 15 46
-1 52 -27 72 -47 57 -57 -8 -6 17 -8 65 -5 42 2 80 7 84 11 4 3 -6 86 -21 184
-37 241 -32 222 -64 222 -16 0 -37 7 -48 15 -22 17 -38 20 -29 5 9 -14 -78
-13 -109 1 -22 10 -28 9 -45 -12 -17 -21 -22 -22 -45 -11 -15 7 -47 12 -71 12
-25 0 -45 3 -45 8 0 4 23 27 52 52 28 25 49 51 47 57 -2 7 -35 -17 -74 -52z
m61 -115 c9 0 14 -11 14 -30 0 -37 -5 -37 -53 -5 -46 32 -49 50 -6 41 17 -3
38 -6 45 -6z m244 1 c0 -11 -89 -91 -100 -91 -11 0 -22 49 -15 70 3 10 14 21
23 23 32 9 92 7 92 -2z m50 -55 c0 -7 3 -35 7 -61 l6 -48 -59 7 c-32 4 -63 11
-67 15 -10 10 81 100 101 101 6 0 12 -6 12 -14z m-359 -89 c-5 -5 -24 -10 -42
-10 -32 -2 -34 0 -37 37 -6 70 5 76 49 26 21 -25 35 -48 30 -53z m-2 -63 c18
-4 30 -12 28 -18 -5 -15 -107 -86 -124 -86 -13 0 -12 36 4 93 5 18 36 22 92
11z m385 -42 c13 -63 -2 -68 -59 -17 -41 37 -47 46 -35 55 8 6 31 9 50 8 33
-3 36 -6 44 -46z"/>
<path d="M5412 6904 c1 -9 11 -18 21 -20 24 -3 22 13 -4 26 -18 10 -20 9 -17
-6z"/>
<path d="M5202 6296 c20 -19 108 -57 108 -45 0 5 -8 12 -17 16 -10 3 -30 15
-45 25 -32 22 -67 26 -46 4z"/>
<path d="M5680 6268 c0 -13 5 -30 10 -38 7 -11 10 -5 10 23 0 20 -4 37 -10 37
-5 0 -10 -10 -10 -22z"/>
<path d="M5428 6255 c-9 -17 -19 -85 -12 -85 4 0 28 17 53 37 61 49 70 58 44
44 -29 -15 -70 -14 -75 1 -3 7 -7 8 -10 3z"/>
<path d="M5588 6153 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5070 6100 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M5715 6068 c10 -46 30 -63 21 -19 -3 18 -6 37 -6 42 0 5 -5 9 -11 9
-7 0 -8 -12 -4 -32z"/>
<path d="M5593 6023 c26 -26 47 -50 47 -54 0 -26 -77 -6 -217 58 -9 4 -13 -7
-13 -38 0 -58 -11 -72 -56 -73 -29 -1 -39 -7 -45 -25 -10 -24 -29 -24 -108 4
-52 17 -49 33 6 40 65 8 96 18 101 33 2 8 -8 12 -30 12 l-33 1 30 25 c34 29
33 29 -49 -9 -32 -16 -66 -27 -74 -25 -26 5 -24 24 5 38 23 11 25 14 10 18
-10 2 -29 -4 -43 -13 -17 -11 -29 -13 -37 -7 -7 6 -30 7 -52 3 -38 -6 -40 -8
-48 -56 -5 -28 -24 -109 -43 -182 -19 -72 -34 -138 -34 -146 0 -11 14 -16 46
-19 47 -3 66 2 63 18 -6 35 2 46 28 39 49 -12 73 -45 34 -45 -11 0 -22 -4 -25
-9 -7 -10 28 -25 99 -41 61 -14 125 -54 125 -79 0 -23 31 -51 57 -51 35 0 52
10 73 44 23 38 54 49 209 77 57 10 106 21 109 24 6 6 -10 20 -33 29 -19 7 10
25 53 32 l42 7 0 -36 c0 -23 6 -37 16 -41 19 -7 89 -8 96 -1 3 3 -14 83 -37
178 -24 95 -46 184 -49 200 -4 15 -13 27 -22 27 -8 0 -25 5 -37 12 -12 6 -31
13 -42 14 -59 7 -109 24 -129 43 -44 41 -38 20 7 -26z m-14 -88 c30 -8 58 -18
63 -23 11 -11 -72 -32 -122 -32 -28 0 -30 3 -30 35 0 41 7 42 89 20z m-352
-99 c29 -10 55 -21 58 -26 3 -5 -13 -14 -36 -20 -39 -11 -43 -10 -70 16 -29
28 -39 60 -16 52 6 -3 36 -13 64 -22z m-146 -38 c24 -27 24 -34 -3 -42 -30 -7
-41 5 -32 38 8 32 11 32 35 4z m518 7 c-9 -9 -23 -13 -33 -9 -14 5 -15 9 -4
15 7 5 22 9 33 9 17 0 18 -2 4 -15z m162 -23 c8 -38 1 -44 -40 -36 -44 9 -49
24 -15 51 36 28 47 25 55 -15z m-465 -37 c4 -8 3 -23 0 -32 -6 -15 -9 -14 -29
5 -19 19 -20 24 -7 32 21 14 30 12 36 -5z m224 5 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-362 -36 c23 -16 29 -34 12
-34 -6 0 -27 7 -48 16 -33 14 -35 17 -17 24 28 12 28 12 53 -6z m536 -6 c16
-9 17 -12 4 -19 -24 -13 -128 -27 -134 -18 -3 5 7 20 23 34 29 25 62 26 107 3z"/>
<path d="M2631 4968 c0 -657 3 -892 12 -918 l12 -35 725 0 c477 0 725 3 725
10 0 6 -65 72 -144 148 l-145 137 -428 0 -429 0 -42 39 -42 40 -5 548 -5 548
-37 65 c-96 168 -175 294 -186 298 -9 3 -12 -195 -11 -880z"/>
<path d="M2127 3843 c-11 -19 15 -148 40 -200 32 -68 121 -161 181 -192 103
-52 64 -51 1350 -51 1037 0 1193 2 1183 14 -7 8 -91 75 -188 148 -96 73 -220
168 -276 211 l-101 77 -1092 0 c-601 0 -1095 -3 -1097 -7z"/>
<path d="M6476 3840 c-510 -4 -646 -8 -634 -17 8 -7 122 -65 254 -129 311
-151 381 -183 568 -258 l90 -36 721 0 c800 0 786 -1 896 66 70 42 130 108 163
178 26 54 51 169 42 192 -6 15 -535 16 -2100 4z"/>
<path d="M1828 3259 c-17 -9 -18 -38 -18 -384 l0 -374 22 -12 c18 -10 24 -8
40 11 16 20 18 46 18 281 0 142 3 259 8 259 4 0 27 -44 51 -98 113 -245 133
-282 157 -282 30 0 37 11 129 213 43 92 82 167 86 167 5 0 9 -114 9 -252 0
-139 3 -263 6 -275 7 -27 40 -36 59 -16 13 12 15 70 15 377 0 354 -1 363 -21
382 -40 36 -44 31 -236 -389 -20 -43 -40 -76 -45 -75 -5 2 -57 105 -114 228
-93 199 -123 251 -143 250 -3 0 -14 -5 -23 -11z"/>
<path d="M2576 3251 c-16 -18 -17 -45 -14 -303 3 -262 5 -286 24 -328 48 -104
152 -154 268 -130 49 10 67 20 105 59 70 70 74 88 78 390 3 145 2 274 -1 287
-8 31 -42 50 -62 33 -11 -9 -14 -59 -14 -278 0 -158 -4 -282 -11 -304 -6 -22
-25 -55 -43 -75 -30 -32 -39 -36 -92 -39 -72 -5 -119 16 -149 67 -19 33 -20
54 -23 325 -2 256 -5 291 -19 303 -22 16 -27 15 -47 -7z"/>
<path d="M3340 3261 c-51 -16 -90 -42 -115 -76 -46 -59 -55 -111 -55 -310 0
-128 4 -196 14 -229 38 -125 142 -182 285 -156 70 12 124 43 141 78 5 13 10
87 10 166 0 176 0 176 -116 176 -90 0 -107 -7 -102 -41 3 -22 8 -24 71 -27
l67 -3 0 -124 0 -124 -32 -18 c-44 -23 -142 -23 -186 1 -23 11 -41 33 -55 64
-19 42 -21 65 -22 237 0 174 2 194 22 237 27 58 68 82 143 82 38 1 68 -6 98
-22 50 -26 65 -27 81 -8 19 23 -4 55 -55 77 -49 22 -156 33 -194 20z"/>
<path d="M3946 3251 c-10 -11 -54 -134 -98 -273 -159 -503 -151 -470 -117
-488 16 -9 25 -8 40 6 10 9 19 21 19 26 0 6 9 41 21 79 l21 70 142 -3 141 -3
25 -78 c27 -85 51 -113 81 -96 26 13 24 30 -15 155 -19 60 -69 217 -110 348
-87 276 -105 307 -150 257z m52 -191 c5 -19 26 -89 46 -155 20 -66 39 -128 42
-137 5 -16 -5 -18 -110 -18 -64 0 -116 4 -116 8 0 12 99 332 106 344 9 14 21
-2 32 -42z"/>
<path d="M4368 3259 c-17 -9 -18 -38 -18 -384 l0 -374 22 -12 c18 -10 24 -8
40 11 16 20 18 46 18 281 0 142 3 259 8 259 4 0 25 -40 47 -88 22 -48 62 -132
90 -187 43 -87 53 -100 75 -100 21 0 31 13 70 90 25 50 66 134 90 188 25 53
48 97 52 97 5 0 8 -119 8 -264 0 -227 2 -267 16 -280 18 -19 20 -19 45 -6 18
10 19 25 19 380 0 322 -2 371 -16 384 -8 9 -19 16 -22 16 -12 0 -52 -31 -52
-39 0 -5 -34 -79 -75 -166 -41 -86 -75 -158 -75 -161 0 -11 -54 -114 -60 -114
-4 0 -53 100 -110 223 -91 196 -126 258 -149 257 -3 0 -14 -5 -23 -11z"/>
<path d="M5302 3253 c-25 -22 -246 -714 -237 -743 7 -21 29 -32 50 -24 8 3 27
45 42 92 l28 87 136 3 c101 2 139 -1 146 -10 6 -7 20 -46 32 -85 25 -84 48
-106 80 -74 16 16 18 26 11 48 -5 15 -36 116 -70 223 -132 418 -151 475 -167
488 -22 16 -27 15 -51 -5z m86 -315 c29 -95 52 -176 52 -180 0 -5 -52 -8 -116
-8 -105 0 -115 2 -110 18 3 9 26 85 51 167 58 188 53 175 62 175 5 0 32 -78
61 -172z"/>
<path d="M3845 2420 c-152 -13 -196 -21 -202 -36 -3 -8 -2 -35 2 -59 l6 -45
64 6 63 7 1 -298 c0 -183 4 -302 10 -308 6 -6 35 -12 66 -15 l55 -4 2 318 3
319 63 9 63 10 -7 45 c-4 25 -8 49 -11 54 -5 8 -62 7 -178 -3z"/>
<path d="M5554 2385 c-102 -61 -169 -246 -162 -448 3 -92 6 -110 33 -162 42
-82 103 -120 189 -120 76 1 116 21 160 82 32 44 75 157 65 168 -3 2 -31 6 -62
7 l-58 3 -22 -55 c-36 -89 -100 -105 -151 -37 -19 25 -21 42 -20 150 0 106 4
132 26 195 30 84 51 112 83 112 34 0 45 -21 54 -100 4 -44 13 -75 22 -81 8 -5
36 -9 63 -9 l49 0 -7 87 c-8 108 -39 183 -88 213 -48 29 -121 27 -174 -5z"/>
<path d="M6074 2397 c-109 -63 -163 -178 -172 -358 -2 -60 0 -128 7 -158 40
-189 187 -282 312 -198 206 139 207 626 2 713 -40 17 -121 18 -149 1z m118
-124 c28 -32 39 -62 47 -130 21 -175 -34 -373 -103 -373 -61 0 -96 66 -103
196 -11 186 37 324 112 324 18 0 39 -8 47 -17z"/>
<path d="M4235 2388 c-49 -5 -94 -13 -99 -18 -22 -22 -34 -666 -13 -687 5 -4
70 -8 145 -8 108 0 145 4 175 17 37 17 37 18 31 60 -4 24 -10 47 -14 51 -4 5
-51 3 -104 -3 -53 -7 -100 -9 -104 -6 -12 13 -15 155 -3 170 7 9 46 16 107 21
l96 7 -7 47 c-4 25 -9 50 -12 54 -2 4 -45 5 -94 1 l-89 -7 0 90 0 90 53 7 c28
3 78 6 110 6 l57 0 0 60 0 60 -72 -1 c-40 -1 -113 -6 -163 -11z"/>
<path d="M4587 2379 c-9 -31 -9 -568 -1 -636 l7 -53 81 1 c45 0 115 6 156 13
l75 12 -3 50 c-2 27 -7 52 -11 56 -4 5 -44 3 -90 -2 -46 -6 -86 -9 -88 -6 -12
11 -14 264 -5 411 6 87 9 161 6 164 -2 3 -31 7 -63 9 -54 4 -59 3 -64 -19z"/>
<path d="M5088 2386 c-48 -6 -92 -16 -97 -21 -7 -7 -13 -140 -17 -343 -7 -320
-7 -332 12 -339 10 -4 78 -8 149 -8 99 0 139 4 166 16 35 16 35 17 30 65 l-6
49 -70 -4 c-39 -3 -88 -5 -110 -5 l-40 -1 0 90 0 90 103 9 103 8 -7 47 c-4 25
-9 50 -11 54 -3 5 -45 5 -94 1 l-89 -7 0 90 0 90 53 7 c28 3 78 6 110 6 l58 0
-3 58 -3 57 -75 1 c-41 1 -114 -3 -162 -10z"/>
<path d="M6821 2304 c-33 -146 -73 -284 -82 -284 -9 0 -14 16 -29 95 -6 33
-21 101 -32 152 l-22 92 -71 6 c-116 11 -119 10 -128 -60 -8 -66 -5 -610 4
-619 3 -3 32 -7 65 -8 54 -3 59 -1 57 17 -1 11 -5 137 -9 279 -5 177 -4 257 3
253 6 -4 13 -21 16 -39 13 -70 86 -404 92 -420 7 -17 81 -36 92 -24 4 3 33
108 64 233 32 124 63 231 70 237 11 11 8 -220 -7 -450 l-6 -91 62 -7 c34 -4
65 -4 69 -1 8 8 8 711 0 719 -3 3 -46 9 -96 12 l-90 7 -22 -99z"/>
</g>
</svg>


		</Box >
	);
	if (disabledLink) {
		return <>{logo}</>;
	}

	return <RouterLink to="">{logo}</RouterLink>;
}
